import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  ZAxis,
} from "recharts";
import CustomTable from "../../../components/CustomTable";
import {
  ChevronDownIcon as ChevronDownIconOutlined,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/16/solid";
import { makeGetRequest } from "../../../utils/makeRequest";
import moment from "moment";

// Constants and configurations for date picker
const TIME_RANGES = {
  years: [moment().year() - 1, moment().year(), moment().year() + 1],
  months: [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ],
};

const ConversationAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({
    offenderEngagementData: [],
    toolUsageByOffender: [],
    monthlyData: [],
  });
  const [selectedOffenders, setSelectedOffenders] = useState([]);
  const [isOffenderDropdownOpen, setIsOffenderDropdownOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = React.useRef(null);
  const [startDate, setStartDate] = useState({
    year: moment().format("YYYY"),
    month: moment().format("MM"),
  });
  const [endDate, setEndDate] = useState({
    year: moment().format("YYYY"),
    month: moment().format("MM"),
  });
  const [selectedDateRange, setSelectedDateRange] = useState("start"); // 'start' or 'end'
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);

  const loadData = async () => {
    setLoading(true);
    try {
      const response = await makeGetRequest({
        path: "/api/conversation/conversation-analytics",
        config: {
          params: {
            startDate: `${startDate.year}-${startDate.month}`,
            endDate: `${endDate.year}-${endDate.month}`,
          },
        },
      });

      if (response.data.success) {
        setAnalyticsData(response.data.data);
        setSelectedOffenders(
          response.data.data.offenderEngagementData.map((o) => o.id)
        );
        setError(null);
      } else {
        setError("Failed to fetch analytics data");
      }
    } catch (err) {
      setError(
        err.message || "An error occurred while fetching analytics data"
      );
    } finally {
      setLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    loadData();
  }, []);

  const getOffenderColor = (index) => {
    const goldenRatio = 0.618033988749895;
    const hue = (index * goldenRatio) % 1;
    const saturation = 0.85;
    const lightness = 0.6;

    return `hsl(${Math.round(hue * 360)}, ${Math.round(
      saturation * 100
    )}%, ${Math.round(lightness * 100)}%)`;
  };

  const abbreviateToolName = (name) => {
    return (
      name
        .split(" ")
        .map((word) => word[0])
        .join(". ") + "."
    );
  };

  // Close date picker when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleYearChange = (direction) => {
    const currentIndex = TIME_RANGES.years.indexOf(parseInt(startDate.year));
    if (direction === "prev" && currentIndex > 0) {
      setStartDate({
        ...startDate,
        year: TIME_RANGES.years[currentIndex - 1].toString(),
      });
    } else if (
      direction === "next" &&
      currentIndex < TIME_RANGES.years.length - 1
    ) {
      setStartDate({
        ...startDate,
        year: TIME_RANGES.years[currentIndex + 1].toString(),
      });
    }
  };

  const handleMonthSelect = (year, month) => {
    if (selectedDateRange === "start") {
      setTempStartDate({ year, month });
      setSelectedDateRange("end");
    } else {
      const newEndDate = { year, month };
      // Compare dates and swap if end date is before start date
      if (
        moment(`${year}-${month}`).isBefore(
          moment(`${tempStartDate.year}-${tempStartDate.month}`)
        )
      ) {
        setTempEndDate(tempStartDate);
        setTempStartDate(newEndDate);
      } else {
        setTempEndDate(newEndDate);
      }
      setSelectedDateRange("start");
    }
  };

  const handleApplyDateRange = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setIsDatePickerOpen(false);
      loadData();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOffenderDropdownOpen && !event.target.closest(".relative")) {
        setIsOffenderDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOffenderDropdownOpen]);

  const toggleOffender = (offenderId) => {
    setSelectedOffenders((prev) =>
      prev.includes(offenderId)
        ? prev.filter((id) => id !== offenderId)
        : [...prev, offenderId]
    );
  };

  const filteredEngagementData = React.useMemo(
    () =>
      analyticsData.offenderEngagementData.filter((d) =>
        selectedOffenders.includes(d.id)
      ),
    [selectedOffenders, analyticsData.offenderEngagementData]
  );

  const filteredMonthlyData = React.useMemo(() => {
    return analyticsData.monthlyData.filter((month) => {
      const monthIndex = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ].indexOf(month.month);

      const monthDate = moment(`${month.month} ${moment().year()}`, "MMM YYYY");
      const startDateMoment = moment(
        `${startDate.year}-${startDate.month}`,
        "YYYY-MM"
      );
      const endDateMoment = moment(
        `${endDate.year}-${endDate.month}`,
        "YYYY-MM"
      );

      return monthDate.isBetween(startDateMoment, endDateMoment, "month", "[]");
    });
  }, [analyticsData.monthlyData, startDate, endDate]);

  const filteredToolUsageData = React.useMemo(
    () =>
      analyticsData.toolUsageByOffender.map((tool) => {
        const filteredCompleted = tool.completed.filter((o) =>
          selectedOffenders.includes(o.offenderId)
        );

        return {
          name: tool.name,
          ...filteredCompleted.reduce(
            (acc, offender) => ({
              ...acc,
              [`offender_${offender.offenderId}`]: offender.count,
            }),
            {}
          ),
        };
      }),
    [selectedOffenders, analyticsData.toolUsageByOffender]
  );

  return (
    <div className="p-4 h-full w-full">
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-600 rounded">
          Error: {error}
        </div>
      )}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">
          Supervisee Conversation Analytics
        </h1>

        <div className="flex gap-4">
          <div className="relative">
            <button
              onClick={() => setIsOffenderDropdownOpen(!isOffenderDropdownOpen)}
              className="border rounded-md p-2 pr-8 bg-white hover:bg-gray-50 flex items-center gap-2 min-w-[200px]"
            >
              <span className="truncate">
                {selectedOffenders.length ===
                analyticsData.offenderEngagementData.length
                  ? "All Supervisees"
                  : selectedOffenders.length === 0
                  ? "Select Supervisees"
                  : `${selectedOffenders.length} Selected`}
              </span>
              <span className="text-xs ml-auto">
                <ChevronDownIconOutlined className="size-6 fill-black" />
              </span>
            </button>

            {isOffenderDropdownOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white border rounded-md shadow-lg z-50 max-h-96 overflow-y-auto">
                <div className="p-2 border-b">
                  <button
                    onClick={() => {
                      if (
                        selectedOffenders.length ===
                        analyticsData.offenderEngagementData.length
                      ) {
                        setSelectedOffenders([]);
                      } else {
                        setSelectedOffenders(
                          analyticsData.offenderEngagementData.map((o) => o.id)
                        );
                      }
                    }}
                    className="w-full text-left px-3 py-2 hover:bg-gray-100 rounded"
                  >
                    {selectedOffenders.length ===
                    analyticsData.offenderEngagementData.length
                      ? "Deselect All"
                      : "Select All"}
                  </button>
                </div>
                <div className="p-2">
                  {analyticsData.offenderEngagementData.map(
                    (offender, index) => (
                      <div
                        key={offender.id}
                        className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer rounded"
                        onClick={() => toggleOffender(offender.id)}
                      >
                        <div
                          className={`w-3 h-3 rounded-full ${
                            selectedOffenders.includes(offender.id)
                              ? "border-2 border-gray-600"
                              : "border border-gray-300 opacity-50"
                          }`}
                          style={{ backgroundColor: getOffenderColor(index) }}
                        />
                        <span className="flex-grow">{offender.name}</span>
                        {selectedOffenders.includes(offender.id) && (
                          <span className="text-green-600">✓</span>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Date picker */}
          <div className="relative" ref={datePickerRef}>
            <button
              onClick={() => {
                setIsDatePickerOpen(!isDatePickerOpen);
                if (!isDatePickerOpen) {
                  setTempStartDate(startDate);
                  setTempEndDate(endDate);
                  setSelectedDateRange("start");
                }
              }}
              className="border rounded-md p-2 bg-white hover:bg-gray-50 flex items-center gap-2 min-w-[200px]"
            >
              <span>
                {
                  TIME_RANGES.months.find((m) => m.value === startDate.month)
                    ?.label
                }{" "}
                {startDate.year} -{" "}
                {
                  TIME_RANGES.months.find((m) => m.value === endDate.month)
                    ?.label
                }{" "}
                {endDate.year}
              </span>
            </button>

            {isDatePickerOpen && (
              <div className="absolute right-0 mt-2 w-80 bg-white border rounded-md shadow-lg z-50">
                <div className="p-4">
                  <div className="flex justify-between items-center mb-4">
                    <button
                      onClick={() => handleYearChange("prev")}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                    <span className="font-semibold">{startDate.year}</span>
                    <button
                      onClick={() => handleYearChange("next")}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <ChevronRightIcon className="h-5 w-5" />
                    </button>
                  </div>

                  <div className="grid grid-cols-4 gap-2">
                    {TIME_RANGES.months.map((month) => {
                      const isStartDate =
                        tempStartDate?.year === startDate.year &&
                        tempStartDate?.month === month.value;
                      const isEndDate =
                        tempEndDate?.year === startDate.year &&
                        tempEndDate?.month === month.value;
                      const isInRange =
                        tempStartDate &&
                        tempEndDate &&
                        moment(`${startDate.year}-${month.value}`).isBetween(
                          moment(
                            `${tempStartDate.year}-${tempStartDate.month}`
                          ),
                          moment(`${tempEndDate.year}-${tempEndDate.month}`),
                          "month",
                          "[]"
                        );

                      return (
                        <button
                          key={month.value}
                          onClick={() =>
                            handleMonthSelect(startDate.year, month.value)
                          }
                          className={`p-2 text-center rounded hover:bg-gray-100
                            ${
                              isStartDate || isEndDate
                                ? "bg-blue-500 text-white hover:bg-blue-600"
                                : ""
                            }
                            ${
                              isInRange && !isStartDate && !isEndDate
                                ? "bg-blue-100"
                                : ""
                            }
                          `}
                        >
                          {month.label}
                        </button>
                      );
                    })}
                  </div>

                  <div className="mt-4 text-sm text-gray-600">
                    {selectedDateRange === "start"
                      ? "Select start month"
                      : "Select end month"}
                  </div>

                  <div className="mt-4 flex justify-end gap-2 border-t pt-4">
                    <button
                      onClick={() => {
                        setIsDatePickerOpen(false);
                        setTempStartDate(startDate);
                        setTempEndDate(endDate);
                        setSelectedDateRange("start");
                      }}
                      className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleApplyDateRange}
                      disabled={!tempStartDate || !tempEndDate}
                      className={`px-4 py-2 text-sm text-white rounded ${
                        tempStartDate && tempEndDate
                          ? "bg-blue-500 hover:bg-blue-600"
                          : "bg-gray-300 cursor-not-allowed"
                      }`}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-3 mb-6">
        <div className="bg-blue-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold">Total Supervisees</h3>
          <p className="text-3xl font-bold text-blue-600">
            {analyticsData.offenderEngagementData.length}
          </p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold">Active Supervisees</h3>
          <p className="text-3xl font-bold text-green-600">
            {filteredEngagementData.length}
          </p>
        </div>
        <div className="bg-purple-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold">Total Tools Started</h3>
          <p className="text-3xl font-bold text-purple-600">
            {filteredEngagementData.reduce(
              (acc, curr) => acc + curr.totalConversations,
              0
            )}
          </p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg">
          <h3 className="text-lg font-semibold">Overall Completion Rate</h3>
          <p className="text-3xl font-bold text-yellow-600">
            {(() => {
              const totalConversations = filteredEngagementData.reduce(
                (acc, curr) => acc + curr.totalConversations,
                0
              );
              if (totalConversations === 0) return "0%";
              return (
                Math.round(
                  (filteredEngagementData.reduce(
                    (acc, curr) => acc + curr.completedTools,
                    0
                  ) /
                    totalConversations) *
                    100
                ) + "%"
              );
            })()}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <div className="bg-gray-50 p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">
            Supervisee Progress Comparison
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <ScatterChart>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="totalConversations"
                name="Number of Tools"
                type="number"
              />
              <YAxis
                dataKey="averageCompletionTime"
                name="Avg. Completion Time"
                unit="d"
                tickFormatter={(value) => (value / 1440).toFixed(1)}
              />
              <Tooltip
                content={({ active, payload }) => {
                  if (active && payload && payload.length) {
                    const data = payload[0].payload;
                    return (
                      <div className="bg-white p-2 border rounded shadow">
                        <p className="font-semibold">{data.name}</p>
                        <p>Started {data.totalConversations} tools</p>
                        <p>
                          Takes {(data.averageCompletionTime / 1440).toFixed(1)}{" "}
                          days on average
                        </p>
                        <p>Finished {data.completedTools} tools</p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
              <Scatter data={filteredEngagementData} fill="#6c63ff">
                {filteredEngagementData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getOffenderColor(
                      analyticsData.offenderEngagementData.findIndex(
                        (o) => o.id === entry.id
                      )
                    )}
                  />
                ))}
              </Scatter>
            </ScatterChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">
            Monthly Activity by Supervisee
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={filteredMonthlyData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="monthYear" interval="preserveStartEnd" />
              <YAxis />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-white p-2 border rounded shadow">
                        <p className="font-semibold">{label}</p>
                        {payload.map((entry, index) => {
                          const offenderId = entry.dataKey.replace(
                            "offender_",
                            ""
                          );
                          const offender =
                            analyticsData.offenderEngagementData.find(
                              (o) => o.id === offenderId
                            );
                          return (
                            <p key={index} style={{ color: entry.color }}>
                              {offender?.name}: {entry.value}
                            </p>
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              {selectedOffenders.map((offenderId, index) => (
                <Line
                  key={offenderId}
                  type="monotone"
                  dataKey={`offender_${offenderId}`}
                  stroke={getOffenderColor(
                    analyticsData.offenderEngagementData.findIndex(
                      (o) => o.id === offenderId
                    )
                  )}
                  dot={{ r: 4 }}
                  activeDot={{ r: 6 }}
                  name={
                    analyticsData.offenderEngagementData.find(
                      (o) => o.id === offenderId
                    )?.name
                  }
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">
            Tool Usage by Supervisee
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={filteredToolUsageData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" tickFormatter={abbreviateToolName} />
              <YAxis />
              <Tooltip
                content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className="bg-white p-2 border rounded shadow">
                        <p className="font-semibold">{label}</p>
                        {payload.map((entry, index) => {
                          const offenderId = entry.dataKey.replace(
                            "offender_",
                            ""
                          );
                          const offender =
                            analyticsData.offenderEngagementData.find(
                              (o) => o.id === offenderId
                            );
                          return (
                            <p key={index} style={{ color: entry.color }}>
                              {offender?.name}: {entry.value}
                            </p>
                          );
                        })}
                      </div>
                    );
                  }
                  return null;
                }}
              />
              {selectedOffenders.map((offenderId, index) => (
                <Bar
                  key={offenderId}
                  dataKey={`offender_${offenderId}`}
                  stackId="stack"
                  fill={getOffenderColor(
                    analyticsData.offenderEngagementData.findIndex(
                      (o) => o.id === offenderId
                    )
                  )}
                  name={
                    analyticsData.offenderEngagementData.find(
                      (o) => o.id === offenderId
                    )?.name
                  }
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-gray-50 p-4 rounded-lg shadow lg:col-span-3">
          <CustomTable
            skeleton={loading}
            leftButtons={
              <h2 className="text-xl font-semibold mb-4">
                Individual Performance
              </h2>
            }
            data={filteredEngagementData}
            columns={[
              {
                key: "name",
                label: "Name",
                render: (value) => (
                  <span className="font-semibold">{value}</span>
                ),
              },
              {
                key: "totalConversations",
                label: "Total Tools",
              },
              {
                key: "completedTools",
                label: "Completed",
              },
              {
                key: "averageCompletionTime",
                label: "Avg Time (days)",
                render: (value) => (value / 1440).toFixed(1),
              },
              {
                key: "lastActive",
                label: "Last Active",
                render: (value) =>
                  value ? new Date(value).toLocaleDateString() : "N/A",
              },
            ]}
            searchable={true}
            sortable={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ConversationAnalytics;
