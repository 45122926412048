import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import CustomTable from "../../../components/CustomTable";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/16/solid";
import { makeGetRequest } from "../../../utils/makeRequest";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

// Constants and configurations
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

const TOOL_TYPES = {
  COST_BENEFIT: "Cost Benefit Analysis",
  SOCIAL_SKILLS: "Social Skills",
  BEHAVIOR_CHAIN: "Behavior Chain",
  PROBLEM_SOLVING: "Problem Solving",
};

const STATUS_CONFIG = {
  COMPLETED: {
    bgColor: "bg-green-100",
    textColor: "text-green-800",
    icon: "✓",
  },
  IN_PROGRESS: {
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-800",
    icon: "↻",
  },
  NOT_STARTED: {
    bgColor: "bg-gray-100",
    textColor: "text-gray-800",
    icon: "○",
  },
};

// Date range helper functions
const generateDateRanges = () => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const years = [currentYear - 1, currentYear, currentYear + 1];
  const months = [
    { value: "01", label: "Jan" },
    { value: "02", label: "Feb" },
    { value: "03", label: "Mar" },
    { value: "04", label: "Apr" },
    { value: "05", label: "May" },
    { value: "06", label: "Jun" },
    { value: "07", label: "Jul" },
    { value: "08", label: "Aug" },
    { value: "09", label: "Sep" },
    { value: "10", label: "Oct" },
    { value: "11", label: "Nov" },
    { value: "12", label: "Dec" },
  ];

  return { years, months };
};

const TIME_RANGES = generateDateRanges();

// Mock data
const MOCK_SUPERVISEES = [
  { id: "1", firstName: "John", lastName: "Doe" },
  { id: "2", firstName: "Jane", lastName: "Smith" },
  { id: "3", firstName: "Michael", lastName: "Johnson" },
  { id: "4", firstName: "Sarah", lastName: "Williams" },
  { id: "5", firstName: "Robert", lastName: "Brown" },
];

// Static mock data for each supervisee
const MOCK_ANALYTICS_DATA = {
  1: {
    assignedTools: [
      {
        id: 1,
        type: TOOL_TYPES.COST_BENEFIT,
        status: "COMPLETED",
        assignedDate: "2024-02-15",
        completedDate: "2024-02-20",
        assignedBy: "Officer Smith",
        progress: 100,
      },
      {
        id: 2,
        type: TOOL_TYPES.SOCIAL_SKILLS,
        status: "IN_PROGRESS",
        assignedDate: "2024-03-01",
        completedDate: null,
        assignedBy: "Officer Johnson",
        progress: 60,
      },
    ],
    selfInitiatedTools: [
      {
        id: 3,
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        status: "COMPLETED",
        startDate: "2024-02-10",
        completedDate: "2024-02-12",
        progress: 100,
      },
    ],
    monthlyToolActivity: [
      { month: "Jan", assigned: 2, selfInitiated: 1, completed: 2 },
      { month: "Feb", assigned: 3, selfInitiated: 2, completed: 4 },
      { month: "Mar", assigned: 1, selfInitiated: 1, completed: 1 },
    ],
    toolUsageByType: [
      {
        type: TOOL_TYPES.COST_BENEFIT,
        completed: 2,
        inProgress: 1,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.SOCIAL_SKILLS,
        completed: 1,
        inProgress: 2,
        notStarted: 1,
      },
      {
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        completed: 3,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.PROBLEM_SOLVING,
        completed: 1,
        inProgress: 1,
        notStarted: 1,
      },
    ],
  },
  2: {
    assignedTools: [
      {
        id: 1,
        type: TOOL_TYPES.PROBLEM_SOLVING,
        status: "COMPLETED",
        assignedDate: "2024-02-20",
        completedDate: "2024-02-25",
        assignedBy: "Officer Smith",
        progress: 100,
      },
      {
        id: 2,
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        status: "NOT_STARTED",
        assignedDate: "2024-03-05",
        completedDate: null,
        assignedBy: "Officer Johnson",
        progress: 0,
      },
    ],
    selfInitiatedTools: [
      {
        id: 3,
        type: TOOL_TYPES.COST_BENEFIT,
        status: "IN_PROGRESS",
        startDate: "2024-03-01",
        completedDate: null,
        progress: 75,
      },
    ],
    monthlyToolActivity: [
      { month: "Jan", assigned: 1, selfInitiated: 1, completed: 1 },
      { month: "Feb", assigned: 2, selfInitiated: 1, completed: 2 },
      { month: "Mar", assigned: 2, selfInitiated: 2, completed: 1 },
    ],
    toolUsageByType: [
      {
        type: TOOL_TYPES.COST_BENEFIT,
        completed: 1,
        inProgress: 1,
        notStarted: 1,
      },
      {
        type: TOOL_TYPES.SOCIAL_SKILLS,
        completed: 2,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        completed: 1,
        inProgress: 1,
        notStarted: 1,
      },
      {
        type: TOOL_TYPES.PROBLEM_SOLVING,
        completed: 2,
        inProgress: 0,
        notStarted: 0,
      },
    ],
  },
  3: {
    assignedTools: [
      {
        id: 1,
        type: TOOL_TYPES.SOCIAL_SKILLS,
        status: "COMPLETED",
        assignedDate: "2024-02-10",
        completedDate: "2024-02-15",
        assignedBy: "Officer Smith",
        progress: 100,
      },
    ],
    selfInitiatedTools: [
      {
        id: 2,
        type: TOOL_TYPES.PROBLEM_SOLVING,
        status: "IN_PROGRESS",
        startDate: "2024-03-01",
        completedDate: null,
        progress: 45,
      },
      {
        id: 3,
        type: TOOL_TYPES.COST_BENEFIT,
        status: "COMPLETED",
        startDate: "2024-02-20",
        completedDate: "2024-02-28",
        progress: 100,
      },
    ],
    monthlyToolActivity: [
      { month: "Jan", assigned: 0, selfInitiated: 2, completed: 1 },
      { month: "Feb", assigned: 1, selfInitiated: 2, completed: 2 },
      { month: "Mar", assigned: 0, selfInitiated: 1, completed: 0 },
    ],
    toolUsageByType: [
      {
        type: TOOL_TYPES.COST_BENEFIT,
        completed: 1,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.SOCIAL_SKILLS,
        completed: 1,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        completed: 0,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.PROBLEM_SOLVING,
        completed: 0,
        inProgress: 1,
        notStarted: 0,
      },
    ],
  },
  4: {
    assignedTools: [
      {
        id: 1,
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        status: "IN_PROGRESS",
        assignedDate: "2024-03-01",
        completedDate: null,
        assignedBy: "Officer Johnson",
        progress: 30,
      },
      {
        id: 2,
        type: TOOL_TYPES.COST_BENEFIT,
        status: "NOT_STARTED",
        assignedDate: "2024-03-05",
        completedDate: null,
        assignedBy: "Officer Smith",
        progress: 0,
      },
    ],
    selfInitiatedTools: [],
    monthlyToolActivity: [
      { month: "Jan", assigned: 0, selfInitiated: 0, completed: 0 },
      { month: "Feb", assigned: 0, selfInitiated: 0, completed: 0 },
      { month: "Mar", assigned: 2, selfInitiated: 0, completed: 0 },
    ],
    toolUsageByType: [
      {
        type: TOOL_TYPES.COST_BENEFIT,
        completed: 0,
        inProgress: 0,
        notStarted: 1,
      },
      {
        type: TOOL_TYPES.SOCIAL_SKILLS,
        completed: 0,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        completed: 0,
        inProgress: 1,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.PROBLEM_SOLVING,
        completed: 0,
        inProgress: 0,
        notStarted: 0,
      },
    ],
  },
  5: {
    assignedTools: [
      {
        id: 1,
        type: TOOL_TYPES.SOCIAL_SKILLS,
        status: "COMPLETED",
        assignedDate: "2024-02-01",
        completedDate: "2024-02-10",
        assignedBy: "Officer Smith",
        progress: 100,
      },
      {
        id: 2,
        type: TOOL_TYPES.PROBLEM_SOLVING,
        status: "COMPLETED",
        assignedDate: "2024-02-15",
        completedDate: "2024-02-25",
        assignedBy: "Officer Johnson",
        progress: 100,
      },
    ],
    selfInitiatedTools: [
      {
        id: 3,
        type: TOOL_TYPES.COST_BENEFIT,
        status: "IN_PROGRESS",
        startDate: "2024-03-01",
        completedDate: null,
        progress: 80,
      },
    ],
    monthlyToolActivity: [
      { month: "Jan", assigned: 0, selfInitiated: 0, completed: 0 },
      { month: "Feb", assigned: 2, selfInitiated: 0, completed: 2 },
      { month: "Mar", assigned: 0, selfInitiated: 1, completed: 0 },
    ],
    toolUsageByType: [
      {
        type: TOOL_TYPES.COST_BENEFIT,
        completed: 0,
        inProgress: 1,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.SOCIAL_SKILLS,
        completed: 1,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.BEHAVIOR_CHAIN,
        completed: 0,
        inProgress: 0,
        notStarted: 0,
      },
      {
        type: TOOL_TYPES.PROBLEM_SOLVING,
        completed: 1,
        inProgress: 0,
        notStarted: 0,
      },
    ],
  },
};

// Table columns configuration
const TABLE_COLUMNS = [
  {
    key: "type",
    label: "Tool Type",
    render: (value) => <span className="font-semibold">{value}</span>,
  },
  {
    key: "source",
    label: "Source",
    render: (value) => (
      <span
        className={`px-2 py-1 rounded text-sm ${
          value === "Assigned"
            ? "bg-blue-100 text-blue-800"
            : "bg-green-100 text-green-800"
        }`}
      >
        {value}
      </span>
    ),
  },
  {
    key: "assignedBy",
    label: "Assigned By",
  },
  {
    key: "startDate",
    label: "Start Date",
    render: (value) => new Date(value).toLocaleDateString(),
  },
  {
    key: "completedDate",
    label: "Completed Date",
    render: (value) => (value ? new Date(value).toLocaleDateString() : "-"),
  },
  {
    key: "status",
    label: "Status",
    render: (value) => {
      const config = STATUS_CONFIG[value];
      return (
        <div className="flex items-center gap-2">
          <span
            className={`px-2 py-1 rounded text-sm ${config.bgColor} ${config.textColor} flex items-center gap-1`}
          >
            <span>{config.icon}</span>
            <span>{value.replace("_", " ")}</span>
          </span>
        </div>
      );
    },
  },
];

const SuperviseeAnalytics = () => {
  const [loading, setLoading] = useState(true);
  const [supervisees, setSupervisees] = useState([]);
  const [selectedSupervisee, setSelectedSupervisee] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [analyticsData, setAnalyticsData] = useState(null);
  const datePickerRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [startDate, setStartDate] = useState({
    year: moment().format("YYYY"),
    month: moment().format("MM"),
  });
  const [endDate, setEndDate] = useState({
    year: moment().format("YYYY"),
    month: moment().format("MM"),
  });
  const [selectedDateRange, setSelectedDateRange] = useState("start"); // 'start' or 'end'
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);

  // Fetch analytics data function
  const fetchAnalytics = React.useCallback(
    async (supervisee, dateRange = null) => {
      if (!supervisee?.userId) {
        return;
      }

      setLoading(true);
      try {
        const response = await makeGetRequest({
          path: `/api/user/supervisee-analytics?superviseeId=${
            supervisee.userId
          }${
            dateRange
              ? `&startDate=${dateRange.startDate.year}-${dateRange.startDate.month}&endDate=${dateRange.endDate.year}-${dateRange.endDate.month}`
              : `&startDate=${startDate.year}-${startDate.month}&endDate=${endDate.year}-${endDate.month}`
          }`,
          config: {},
          navigate,
          location,
        });

        if (response?.data?.success) {
          setAnalyticsData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching analytics:", error);
      } finally {
        setLoading(false);
      }
    },
    [navigate, location]
  );

  // Fetch supervisees list
  useEffect(() => {
    const fetchSupervisees = async () => {
      try {
        const response = await makeGetRequest({
          path: "/api/user/get-offenders",
          config: {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          },
          navigate,
          location,
        });

        if (response?.data?.success) {
          const offenders = response.data.data.offenders;
          setSupervisees(offenders);
          if (offenders.length > 0) {
            setSelectedSupervisee(offenders[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching supervisees:", error);
      }
    };

    fetchSupervisees();
  }, [navigate, location]);

  // Fetch analytics when supervisee is first set
  useEffect(() => {
    if (selectedSupervisee) {
      fetchAnalytics(selectedSupervisee);
    }
  }, [selectedSupervisee]);

  // Close date picker when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleYearChange = (direction) => {
    const currentIndex = TIME_RANGES.years.indexOf(parseInt(startDate.year));
    if (direction === "prev" && currentIndex > 0) {
      setStartDate({
        ...startDate,
        year: TIME_RANGES.years[currentIndex - 1].toString(),
      });
    } else if (
      direction === "next" &&
      currentIndex < TIME_RANGES.years.length - 1
    ) {
      setStartDate({
        ...startDate,
        year: TIME_RANGES.years[currentIndex + 1].toString(),
      });
    }
  };

  const getToolCompletionRate = () => {
    const totalTools = [
      ...analyticsData.assignedTools,
      ...analyticsData.selfInitiatedTools,
    ];
    const completed = totalTools.filter(
      (tool) => tool.status === "COMPLETED"
    ).length;
    return totalTools.length > 0
      ? Math.round((completed / totalTools.length) * 100)
      : 0;
  };

  const abbreviateToolName = (name) => {
    return (
      name
        .split(" ")
        .map((word) => word[0])
        .join(". ") + "."
    );
  };

  const getActiveToolsCount = () => {
    return [
      ...analyticsData.assignedTools,
      ...analyticsData.selfInitiatedTools,
    ].filter((tool) => tool.status === "IN_PROGRESS").length;
  };

  const handleMonthSelect = (year, month) => {
    if (selectedDateRange === "start") {
      setTempStartDate({ year, month });
      setSelectedDateRange("end");
    } else {
      const newEndDate = { year, month };
      // Compare dates and swap if end date is before start date
      if (
        moment(`${year}-${month}`).isBefore(
          moment(`${tempStartDate.year}-${tempStartDate.month}`)
        )
      ) {
        setTempEndDate(tempStartDate);
        setTempStartDate(newEndDate);
      } else {
        setTempEndDate(newEndDate);
      }
      setSelectedDateRange("start");
    }
  };

  const handleApplyDateRange = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setIsDatePickerOpen(false);

      // Make API request with the new date range
      if (selectedSupervisee) {
        console.log("fetching analytics", tempStartDate, tempEndDate);
        fetchAnalytics(selectedSupervisee, {
          startDate: tempStartDate,
          endDate: tempEndDate,
        });
      }
    }
  };

  return (
    <div className="p-4 h-full w-full">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Supervisee Analytics</h1>

        <div className="flex gap-4">
          <div className="relative">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="border rounded-md p-2 pr-8 bg-white hover:bg-gray-50 flex items-center gap-2 min-w-[200px]"
            >
              <span className="truncate">
                {selectedSupervisee
                  ? `${selectedSupervisee.firstName} ${selectedSupervisee.lastName}`
                  : "Select Supervisee"}
              </span>
              <span className="text-xs ml-auto">
                <ChevronDownIcon className="size-6 fill-black" />
              </span>
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white border rounded-md shadow-lg z-50 max-h-96 overflow-y-auto">
                {supervisees.map((supervisee) => (
                  <div
                    key={supervisee.id}
                    className="flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer rounded"
                    onClick={() => {
                      setSelectedSupervisee(supervisee);
                      setIsDropdownOpen(false);
                    }}
                  >
                    <span className="flex-grow">{`${supervisee.firstName} ${supervisee.lastName}`}</span>
                    {selectedSupervisee?.id === supervisee.id && (
                      <span className="text-green-600">✓</span>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Date picker */}
          <div className="relative" ref={datePickerRef}>
            <button
              onClick={() => {
                setIsDatePickerOpen(!isDatePickerOpen);
                if (!isDatePickerOpen) {
                  setTempStartDate(startDate);
                  setTempEndDate(endDate);
                  setSelectedDateRange("start");
                }
              }}
              className="border rounded-md p-2 bg-white hover:bg-gray-50 flex items-center gap-2 min-w-[200px]"
            >
              <span>
                {
                  TIME_RANGES.months.find((m) => m.value === startDate.month)
                    ?.label
                }{" "}
                {startDate.year} -{" "}
                {
                  TIME_RANGES.months.find((m) => m.value === endDate.month)
                    ?.label
                }{" "}
                {endDate.year}
              </span>
            </button>

            {isDatePickerOpen && (
              <div className="absolute right-0 mt-2 w-80 bg-white border rounded-md shadow-lg z-50">
                <div className="p-4">
                  <div className="flex justify-between items-center mb-4">
                    <button
                      onClick={() => handleYearChange("prev")}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <ChevronLeftIcon className="h-5 w-5" />
                    </button>
                    <span className="font-semibold">{startDate.year}</span>
                    <button
                      onClick={() => handleYearChange("next")}
                      className="p-1 hover:bg-gray-100 rounded"
                    >
                      <ChevronRightIcon className="h-5 w-5" />
                    </button>
                  </div>

                  <div className="grid grid-cols-4 gap-2">
                    {TIME_RANGES.months.map((month) => {
                      const isStartDate =
                        tempStartDate?.year === startDate.year &&
                        tempStartDate?.month === month.value;
                      const isEndDate =
                        tempEndDate?.year === startDate.year &&
                        tempEndDate?.month === month.value;
                      const isInRange =
                        tempStartDate &&
                        tempEndDate &&
                        moment(`${startDate.year}-${month.value}`).isBetween(
                          moment(
                            `${tempStartDate.year}-${tempStartDate.month}`
                          ),
                          moment(`${tempEndDate.year}-${tempEndDate.month}`),
                          "month",
                          "[]"
                        );

                      return (
                        <button
                          key={month.value}
                          onClick={() =>
                            handleMonthSelect(startDate.year, month.value)
                          }
                          className={`p-2 text-center rounded hover:bg-gray-100
                            ${
                              isStartDate || isEndDate
                                ? "bg-blue-500 text-white hover:bg-blue-600"
                                : ""
                            }
                            ${
                              isInRange && !isStartDate && !isEndDate
                                ? "bg-blue-100"
                                : ""
                            }
                          `}
                        >
                          {month.label}
                        </button>
                      );
                    })}
                  </div>

                  <div className="mt-4 text-sm text-gray-600">
                    {selectedDateRange === "start"
                      ? "Select start month"
                      : "Select end month"}
                  </div>

                  <div className="mt-4 flex justify-end gap-2 border-t pt-4">
                    <button
                      onClick={() => {
                        setIsDatePickerOpen(false);
                        setTempStartDate(startDate);
                        setTempEndDate(endDate);
                        setSelectedDateRange("start");
                      }}
                      className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleApplyDateRange}
                      disabled={!tempStartDate || !tempEndDate}
                      className={`px-4 py-2 text-sm text-white rounded ${
                        tempStartDate && tempEndDate
                          ? "bg-blue-500 hover:bg-blue-600"
                          : "bg-gray-300 cursor-not-allowed"
                      }`}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedSupervisee && analyticsData && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3 mb-6">
            <div className="bg-blue-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold">Assigned Tools</h3>
              <p className="text-3xl font-bold text-blue-600">
                {analyticsData.assignedTools.length}
              </p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold">Self-Initiated Tools</h3>
              <p className="text-3xl font-bold text-green-600">
                {analyticsData.selfInitiatedTools.length}
              </p>
            </div>
            <div className="bg-purple-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold">Active Tools</h3>
              <p className="text-3xl font-bold text-purple-600">
                {getActiveToolsCount()}
              </p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold">Completion Rate</h3>
              <p className="text-3xl font-bold text-yellow-600">
                {getToolCompletionRate()}%
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6">
            <div className="bg-gray-50 p-4 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">Tool Usage by Type</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={analyticsData.toolUsageByType}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="type" tickFormatter={abbreviateToolName} />
                  <YAxis />
                  <Tooltip
                    content={({ active, payload, label }) => {
                      if (active && payload && payload.length) {
                        return (
                          <div className="bg-white p-2 border rounded shadow">
                            <p className="font-semibold">{label}</p>
                            {payload.map((entry, index) => (
                              <p key={index} style={{ color: entry.color }}>
                                {entry.name}: {entry.value}
                              </p>
                            ))}
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend />
                  <Bar
                    dataKey="completed"
                    stackId="a"
                    fill="#4CAF50"
                    name="Completed"
                  />
                  <Bar
                    dataKey="inProgress"
                    stackId="a"
                    fill="#2196F3"
                    name="In Progress"
                  />
                  <Bar
                    dataKey="notStarted"
                    stackId="a"
                    fill="#FFC107"
                    name="Not Started"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">
                Monthly Tool Activity
              </h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={analyticsData.monthlyToolActivity}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="assigned"
                    stroke="#2196F3"
                    name="Assigned"
                  />
                  <Line
                    type="monotone"
                    dataKey="selfInitiated"
                    stroke="#4CAF50"
                    name="Self-Initiated"
                  />
                  <Line
                    type="monotone"
                    dataKey="completed"
                    stroke="#FFC107"
                    name="Completed"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg shadow">
              <h2 className="text-xl font-semibold mb-4">Tool History</h2>
              <CustomTable
                skeleton={loading}
                data={[
                  ...analyticsData.assignedTools.map((tool) => ({
                    type: tool.type,
                    source: "Assigned",
                    assignedBy: tool.assignedBy,
                    startDate: tool.assignedDate,
                    completedDate: tool.completedDate,
                    status: tool.status,
                  })),
                  ...analyticsData.selfInitiatedTools.map((tool) => ({
                    type: tool.type,
                    source: "Self-Initiated",
                    assignedBy: "-",
                    startDate: tool.startDate,
                    completedDate: tool.completedDate,
                    status: tool.status,
                  })),
                ].sort((a, b) => new Date(b.startDate) - new Date(a.startDate))}
                columns={TABLE_COLUMNS}
                searchable={true}
                sortable={true}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SuperviseeAnalytics;
