import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { store } from "../../reducers/store";
import ConversationsBar from "./components/ConversationsBar";
import ConversationBox from "./components/ConversationBox";
import { botpressUrl } from "../../utils/constants";
import NewConversationModal from "./components/NewConversationModal";
import "./HomePage.css";
import FlowProvider from "../../providers/FlowProvider";
import { sanitizeAndParseJson } from "../../utils/sanitizeAndParseJson";
import { setCurrentConversation as setCurrentConversationToReducer } from "../../reducers/app.reducer";
import { makeGetRequest } from "../../utils/makeRequest";
import deleteQueryParam from "../../utils/deleteQueryParam";
import addCheckpoint from "../../utils/addCheckpoint";

function HomePage() {
  const flowProviderRef = useRef()
  const conversationBoxRef = useRef()
  const [isInitialized, setInitializedState] = useState(false);
  const [isConversationLoaded, setConversationLoaded] = useState(false);
  const [currentConversationDetails, setCurrentConversationDetails] = useState({
    conversationTitle: "",
    conversationDescription: "",
    conversationId: "",
    isLocked: false
  });
  const [conversations, setConversations] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const conversationConvId = searchParams.get("conversationId");
  const authReducer = store.getState().authReducer;
  const userId = authReducer.userId;
  const userToken = localStorage.getItem("user-token");
  const [avatarText, setAvatarText] = useState("");
  const [showAvatar, setShowAvatar] = useState(false);
  const [showCostBenefitForm, setShowCostBenefitForm] = useState(false);
  const [costBenefitFormData, setCostBenefitFormData] = useState(null);
  const [showSocialSkillForm, setShowSocialSkillForm] = useState(false);
  const [socialSkillFormData, setShowSocialSkillFormData] = useState(null);
  const [showBehaviorChainForm, setShowBehaviorChainForm] = useState(null);
  const [behaviorChainFormText, setBehaviorChainFormText] = useState("");
  const [showProblemSolvingForm, setShowProblemSolvingForm] = useState(false);
  const [problemSolvingFormData, setProblemSolvingFormData] = useState(null);
  const [idsForCheckpoint, setIdsForCheckpoint] = useState({});

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const reloadPage = () => {
    window.location.reload();
  }

  const navigate = useNavigate();
  const location = useLocation();

  // Function to fetch conversations
  const fetchConversations = async () => {
    return new Promise(async (resolve, reject) => {
      const response = await makeGetRequest({
        path: "/api/conversation/list-conversations",
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        }, navigate, location
      });
      if (response.data.data.length > 0) {
        setConversations(response.data.data);
        if (conversationConvId) {
          response.data.data.map((conversation) => {
            if (conversation.conversationId === conversationConvId) {
              store.dispatch(setCurrentConversationToReducer(conversation));
              return setCurrentConversationDetails(conversation);
            }
            return "";
          });
        } else {
          store.dispatch(setCurrentConversationToReducer(response.data.data[0]));
          setCurrentConversationDetails(response.data.data[0]);
        }
        setConversationLoaded(true);

        resolve(response.data.data[0]);
      } else {
        resolve();
      }
    });
  };

  const onCreateConversation = async () => {
    const conversation = await fetchConversations();

    if (isInitialized) {
      if (conversation.isToolStarted && conversation.assignedTool) {
        openConversation(
          conversation,
          "&newConversation=true&followUp=" + conversation.assignedTool
        );
      } else {
        openConversation(conversation, "&newConversation=true");
      }
    }
  };

  const openConversation = (conversation, additionalQueryString = null) => {
    let url = "/?conversationId=" + conversation.conversationId;

    url = additionalQueryString ? url + additionalQueryString : url;
    window.open(url, "_self");
  };

  useEffect(() => {
    fetchConversations();
    if (!isInitialized && conversations.length > 0) {
      if (userId && userToken) {
        setInitializedState(true);
        if (conversationConvId) {
          pullCurrentConversationCheckpointData(conversationConvId);
        } else {
          pullCurrentConversationCheckpointData(conversations[0].conversationId);
        }
      }
    }
    // eslint-disable-next-line
  }, [isInitialized, userId, userToken, isConversationLoaded]);

  const loadSpecificFlow = (flowId) => {
    setTimeout(() => {
      window.botpressWebChat.sendEvent({
        type: "jumpTo",
        channel: "web",
        payload: {
          flowId: flowId,
        },
      });
    }, 1000);
  };

  const loadSpecificNode = (flowId, nodeId) => {
    setTimeout(() => {
      window.botpressWebChat.sendEvent({
        type: "jumpTo",
        channel: "web",
        payload: {
          flowId: flowId,
          nodeId: nodeId,
        },
      });
    }, 1000);
  };

  const pullCurrentConversationCheckpointData = async (conversationId) => {
    const response = await makeGetRequest({
      path: `/api/conversation/fetch-last-checkpoint/${conversationId}`,
      navigate, location
    });

    initializeChatBot(userId, JSON.parse(userToken), response.data.data, {
      conversationId: conversationId,
    });
  }


  const initializeChatBot = async (userId, userToken, checkpointData, chatOptions = {}) => {
    try {
      const rootLink = window.location.origin;
      //  maxMessagesHistory: 500,
      window.botpressWebChat.init(
        {
          host: botpressUrl,
          userId: userId,
          botId: "odrc",
          hideWidget: true,
          showPoweredBy: false,
          externalAuthToken: userToken,
          extraStylesheet: rootLink + "/styles/chatbot.css",
          botName: "Kanka Bot",
          enableTranscriptDownload: false,
          showConversationsButton: false,
          viewMode: "Fullscreen",
          ...chatOptions,
        },
        "#conversation-chat-container"
      );

      window.addEventListener("message", function (event) {
        //Identifies when the bot bubble is clicked and the sends 'proactive-trigger' event

        // This used to be configChanged but started to give issue about can't send event before webchat is ready.
        // So, changed to webchatReady. We need to understand what is going on here.
        // Sometimes webchatReady is not working and sometimes configChanged is not working.
        if (event.data.name === "webchatReady") {
          setTimeout(function () {
            //window.botpressWebChat.configure({ externalAuthToken: 'my.jwt.token' })
            window.botpressWebChat.sendEvent({
              type: "setUserToken",
              channel: "web",
              payload: { userToken: userToken },
            });
          }, 1000);
        }

        if (event.data.name === "webchatLoaded") {
          const followUpParam = searchParams.get("followUp");
          const flowParam = searchParams.get("flow");
          if (followUpParam) {
            loadSpecificFlow(followUpParam);
          }
          if (flowParam) {
            loadSpecificFlow(flowParam);
          }
          deleteQueryParam("followUp");
          deleteQueryParam("flow");
        }

        if (event.data.name === "webchatReady") {
          const isNewConversation = searchParams.get("newConversation");
          if (isNewConversation) {
            //Send first message to user as a chatbot
            loadSpecificFlow("main")

            deleteQueryParam("newConversation");
          }

          if (checkpointData && checkpointData.lastCheckpoint) {
            loadSpecificNode(checkpointData.lastCheckpoint?.flowId, checkpointData.lastCheckpoint?.nodeId)
          }
        }

        if (event.data.actionName === "displayAvatar") {
          setAvatarText(event.data.data);
          setShowAvatar(true);
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });
          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'AVATAR', status: 'STARTED', navigate, location });
        }

        if (event.data.actionName === "videoRecording") {
          const escapedString = sanitizeAndParseJson(event.data.data);
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });
          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'VIDEO_RECORDING', status: 'STARTED', navigate, location });

          flowProviderRef.current?.videoRecorder.showVideoRecorder({
            title: escapedString.title,
            description: escapedString.description,
            currentConversationId: currentConversationDetails?.conversationId,
          });
        }

        if (event.data.actionName === "displayLastVideoRecording") {
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });
          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'VIDEO_PREVIEW', status: 'STARTED', navigate, location });
          flowProviderRef.current?.displayVideo.showVideoDisplayer({
            currentConversationId: currentConversationDetails?.conversationId,
          });
        }

        if (event.data.actionName === "displayCostBenefitForm") {
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });

          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'TOOL_FORM', status: 'STARTED', navigate, location });
          const escapedString = sanitizeAndParseJson(event.data.data);
          const data = escapedString;
          setCostBenefitFormData(data);
          setShowCostBenefitForm(true);
        }

        if(event.data.actionName === "displaySocialSkillForm"){
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });
          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'TOOL_FORM', status: 'STARTED', navigate, location });
          const escapedString = sanitizeAndParseJson(event.data.data);
          const data = escapedString;
          setShowSocialSkillFormData(data);
          setShowSocialSkillForm(true);
        }

        if (event.data.actionName === "loadConversation") {
          const conversationId = event.data.data
          openConversation({ conversationId });
        }

        if (event.data.actionName === "returnToDashboard") {
          const url = "/dashboard"
          window.open(url, "_self");
        }

        if (event.data.actionName === "editToolForm") {
          conversationBoxRef.current.handleEditClick()
        }

        if (event.data.actionName === "displayProblemSolvingForm"){
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });
          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'TOOL_FORM', status: 'STARTED', navigate, location });
          const sanitizedData = sanitizeAndParseJson(event.data.data);
          setProblemSolvingFormData(sanitizedData);
          setShowProblemSolvingForm(true);
        }

        if (event.data.actionName === "reloadPage") {
          reloadPage();
        }

        if (event.data.actionName === "displayBehaviorChainForm") {
          setIdsForCheckpoint(() => { return { flowId: event.data?.flowId, nodeId: event.data?.nodeId } });
          addCheckpoint({ nodeId: event.data.nodeId, flowId: event.data.flowId, bpConversationId: conversationConvId, name: 'TOOL_FORM', status: 'STARTED', navigate, location });
          const escapedString = sanitizeAndParseJson(event.data.data);
          const data = escapedString;
          setBehaviorChainFormText(data);
          setShowBehaviorChainForm(true);
        }
      });
    } catch (error) {
      console.log("script error:", error);
    }
  };

  return (
    <div className="relative h-screen w-screen flex flex-row items-center justify-center ">
      <FlowProvider
        ref={flowProviderRef}
        avatar={{
          showAvatar,
          avatarText,
          setShowAvatarStatus: (status) => {
            setShowAvatar(status);
          },
        }}
        displayCostBenefitForm={{
          setShowCostBenefitForm,
          costBenefitFormData,
          showCostBenefitForm
        }}
        displayBehaviorChainForm={{
          setShowBehaviorChainForm,
          showBehaviorChainForm,
          behaviorChainFormText
        }}
        displayProblemSolvingForm={{
          setShowProblemSolvingForm,
          showProblemSolvingForm,
          problemSolvingFormData
        }}
        displaySocialSkillForm={{
          setShowSocialSkillForm,
          socialSkillFormData,
          showSocialSkillForm,
        }}
        onClose={() => { conversationBoxRef.current.fetchAttachments(); }}
        nodeIdForCheckpoint={idsForCheckpoint.nodeId}
        flowIdForCheckpoint={idsForCheckpoint.flowId}
        conversationConvId={conversationConvId}
      />
      <NewConversationModal
        isOpen={isOpen}
        openModal={openModal}
        closeModal={closeModal}
        onCreateConversation={onCreateConversation}
      />
      <div className="h-full w-0 hidden lg:flex lg:w-1/5">
        <ConversationsBar
          openModal={() => {
            openModal();
          }}
          currentConversationDetails={currentConversationDetails}
          setCurrentConversationDetails={(currentConversation) => {
            store.dispatch(
              setCurrentConversationToReducer(currentConversation)
            );
            setCurrentConversationDetails(currentConversation);
          }}
          conversations={conversations}
        />
      </div>
      <div className="h-full w-full lg:w-4/5">
        <ConversationBox
          isConversationLoaded={isConversationLoaded}
          currentConversationDetails={currentConversationDetails}
          conversations={conversations}
          openModal={() => {
            openModal();
          }}
          ref= {conversationBoxRef}
        />
      </div>
    </div>
  );
}

export default HomePage;
