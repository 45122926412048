import React, { useState, useEffect } from "react";
import CostBenefit from "../../sources/images/CBA_drug_treatment-1.png";
import html2canvas from "html2canvas";
import CustomButton from "../../components/CustomButton";
import { store } from "../../reducers/store";
import { saveToolForm } from "../../utils/saveToolForm";
import { useLocation, useNavigate } from "react-router-dom";

const CostBenefitForm = ({
  costBenefitFormData,
  setShowCostBenefitForm,
  closeModal,
}) => {
  const [downloadedImage, setDownloadedImage] = useState(null);
  const authReducer = store.getState().authReducer;
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleDateString();

  useEffect(() => {
    generatePNG();

    // eslint-disable-next-line
  }, []);

  const adjustFontSize = (text, elementId) => {
    const wordCount = text.trim().length;
    const element = document.getElementById(elementId);
    if (element) {
      if (wordCount > 188) {
        element.style.fontSize = "11px";
      } else {
        element.style.fontSize = "13px";
      }
    }
  };

  const formatToListByComma = (inputString) => {
    if (inputString.length > 0) {
      const items = inputString.split(",").map((item) => item.trim());
      const list = items.map((item) => `• ${item} </br>`);
      return list.join("\n");
    } else return " ";
  };

  const navigate = useNavigate();
  const location = useLocation();

  const generatePNG = async () => {
    try {
      const container = document.createElement("div");
      // container.style.visibility = "hidden";
      container.style.position = "absolute";
      container.style.width = "1024px";
      container.style.height = "768px";
      document.body.appendChild(container);

      container.innerHTML = `
      <div className="relative w-96 h-88" id="container">
      <img src="${CostBenefit}" alt="Cost Benefit" class="w-full h-full object-contain" />
      <div class="absolute inset-0">
        <div class="flex h-20 w-full inset-y-6 mt-1 absolute">
          <div class="flex w-36 pl-52 h-20 basis-1/2">
            <p class="text-24 items-start mt-10 text-center">
              ${authReducer?.firstName} ${authReducer?.lastName}
            </p>
          </div>
          <div class="w-56 h-8 ml-36 basis-1/2">
            <p class="text-24 mt-10 text-center">
              ${formattedDate}
            </p>
          </div>
        </div>
        <div class="relative flex gap-36 inset-y-28 w-full h-16">
          <div class="flex basis-1/2 max-w-full ml-44 items-center h-16">
            <p class="text-14 w-full h-14 mt-16 overflow-hidden text-center">
              ${costBenefitFormData.behavior}
            </p>
          </div>
          <div class="basis-1/2 mr-20 mt-1 w-full">
            <p class="text-12 w-full h-16 mt-8 overflow-hidden text-center">
              ${costBenefitFormData.alternativeBehavior}
            </p>
          </div>
        </div>
        <div class="absolute text-[15px] ml-40 mt-44 w-8 max-h-28">
          <div class="w-[10rem] max-h-[13rem] overflow-y-hidden px-2 py-2 ml-1" id="behaviorShortTermBenefits">${formatToListByComma(
            costBenefitFormData.behaviorShortTermBenefits
          )}</div>
        </div>
        <div class="text-[15px] ml-80 mt-44 max-w-10 max-h-28">
          <div class="w-[10rem] max-h-[13rem] overflow-y-hidden px-2 py-1 mt-8 ml-3" id="behaviorShortTermCosts">${formatToListByComma(
            costBenefitFormData.behaviorShortTermCosts
          )}</div>
        </div>
        <div class="absolute ml-[10.2rem] ${
          costBenefitFormData.behaviorShortTermCosts ? "mt-28" : "mt-52"
        } w-[10rem] max-h-[12rem] overflow-y-hidden px-2 py-2" id="behaviorLongTermBenefits">${formatToListByComma(
        costBenefitFormData.behaviorLongTermBenefits
      )}</div>
        <div class="absolute ml-[20.7rem] mt-28 w-[10rem] max-h-[12rem] overflow-y-hidden px-2 py-2" id="behaviorLongTermCosts">${formatToListByComma(
          costBenefitFormData.behaviorLongTermCosts
        )}</div>
        <div class="flex absolute ml-40 -mt-28">
          <div>
            <div class="w-[10rem] absolute max-h-[12rem] overflow-y-hidden ml-[39rem] px-2 py-2" id="alternativeBehaviorShortTermBenefits">${formatToListByComma(
              costBenefitFormData.alternativeBehaviorShortTermCosts
            )}</div>
          </div>
          <div class="text-[15px] absolute ml-56 w-20 max-h-28">
            <div class="w-[9.5rem] max-h-[12rem] p-1 overflow-y-hidden ml-60" id="alternativeBehaviorShortTermCosts">${formatToListByComma(
              costBenefitFormData.alternativeBehaviorShortTermBenefits
            )}</div>
          </div>
        </div>
        <div class="flex absolute mt-24 ml-[39rem]">
          <div class="absolute text-[15px] ml-4 mt-4 w-[19rem] max-h-28" id="alternativeBehaviorLongTermCosts">
            <div class="w-[10rem] max-h-[12rem] overflow-y-hidden ml-36 px-2 py-1">${formatToListByComma(
              costBenefitFormData.alternativeBehaviorLongTermCosts
            )}</div>
          </div>
          <div class="text-[15px] basis-1/2 mt-4 w-20 max-h-28">
            <div class="w-[9.5rem] max-h-[12rem] overflow-y-hidden px-2 p-1" id="alternativeBehaviorLongTermBenefits">${formatToListByComma(
              costBenefitFormData.alternativeBehaviorLongTermBenefits
            )}</div>
          </div>
        </div>
      </div>
    </div>
    
      `;

      adjustFontSize(
        costBenefitFormData.behaviorShortTermBenefits,
        "behaviorShortTermBenefits"
      );
      adjustFontSize(
        costBenefitFormData.behaviorShortTermCosts,
        "behaviorShortTermCosts"
      );
      adjustFontSize(
        costBenefitFormData.behaviorLongTermBenefits,
        "behaviorLongTermBenefits"
      );
      adjustFontSize(
        costBenefitFormData.behaviorLongTermCosts,
        "behaviorLongTermCosts"
      );
      adjustFontSize(
        costBenefitFormData.alternativeBehaviorShortTermBenefits,
        "alternativeBehaviorShortTermBenefits"
      );
      adjustFontSize(
        costBenefitFormData.alternativeBehaviorShortTermCosts,
        "alternativeBehaviorShortTermCosts"
      );
      adjustFontSize(
        costBenefitFormData.alternativeBehaviorLongTermBenefits,
        "alternativeBehaviorLongTermBenefits"
      );
      adjustFontSize(
        costBenefitFormData.alternativeBehaviorLongTermCosts,
        "alternativeBehaviorLongTermCosts"
      );

      container.style.visibility = "visible";

      const canvas = await html2canvas(container, {
        width: 1024,
        height: 768,
      });

      const imageData = canvas.toDataURL("image/png");

      setDownloadedImage(imageData);

      document.body.removeChild(container);

      if (
        costBenefitFormData.alternativeBehaviorLongTermBenefits &&
        costBenefitFormData.alternativeBehaviorLongTermCosts.trim() !== ""
      ) {
        // Convert base64 to Blob
        const blob = await fetch(imageData).then((res) => res.blob());
        saveToolForm(blob, "cost-benefit-analysis", navigate, location);
      }
    } catch (error) {
      console.error("Error generating PNG:", error);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadedImage;
    link.download = "CBA-Form.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
      <div className="absolute z-50 w-5/6  xl:w-3/6 xl:h-88 lg:w-3/4 lg:h-88 bg-white rounded-lg  print:w-full print:h-full">
        <div className="relative xsm:space-x-2 mb-4 mt-2 z-50 flex justify-center space-x-4 print:hidden ">
          <CustomButton onClick={handlePrint} buttonColor="primary">
            Print
          </CustomButton>
          <CustomButton onClick={handleDownload} buttonColor="primary">
            Download
          </CustomButton>
          <CustomButton
            buttonColor={"danger"}
            buttonType={"outline"}
            buttonSize={"sm"}
            onClick={() => {
              setShowCostBenefitForm(false);
              setTimeout(() => {
                window.botpressWebChat.sendEvent({
                  type: "proactive-trigger",
                  channel: "web",
                  payload: { text: "CBA Form displayed!" },
                });
              }, 500);
              closeModal();
            }}
            customStyle={"mr-2"}
          >
            Close
          </CustomButton>
        </div>
        <div className="border-t-2 border-dashed border-gray-300 mt-4 pt-4"></div>

        <img
          src={downloadedImage}
          alt="Cost Benefit"
          className="w-full h-full object-contain rounded-lg"
        />
      </div>
    </div>
  );
};

export default CostBenefitForm;
