import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { makePostRequest } from "../utils/makeRequest";
import { useNavigate } from "react-router-dom";

const TypingText = forwardRef(function RenderText(
  { text, delay, infinite, onCompleted, onContinueStatusChange },
  ref
) {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [textChunks, setTextChunks] = useState([]);
  const [currentTextChunk, setCurrentTextChunk] = useState(0);
  const [volume, setVolume] = useState(0);
  const [isBotTalking, setBotTalking] = useState(false);
  const [botAudio, setBotAudio] = useState(null);
  const navigate = useNavigate();

  const chunkTextByPeriods = useCallback((text, maxLength) => {
    const chunks = [];
    let currentChunk = "";

    const sentences = text.match(/[^.!?]+[.!?]+/g) || [];

    for (const sentence of sentences) {
      if (currentChunk.length + sentence.length <= maxLength) {
        currentChunk += sentence;
      } else {
        if (currentChunk) chunks.push(currentChunk.trim());
        currentChunk = sentence;
      }
    }

    if (currentChunk) chunks.push(currentChunk.trim());

    return chunks;
  }, []);

  const synthesizeSpeech = async (text) => {
    try {
      const response = await makePostRequest({
        path: "/api/conversation/speech-synthesis",
        body: {
          text,
        },
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        },
        navigate,
        location: window.location,
      });

      const speech = await response.data.data;

      // Create and configure audio
      const audio = new Audio(
        `data:${speech.contentType};base64,${speech.audioContent}`
      );

      audio.onended = () => {
        setBotTalking(false);
        setVolume(0);
      };

      audio.onerror = () => {
        setBotTalking(false);
        setVolume(0);
        console.error("Audio playback error");
      };

      setBotAudio(audio);

      await audio.play();
      return audio;
    } catch (error) {
      console.error("Speech synthesis error:", error);
      setBotTalking(false);
      setVolume(0);
    }
  };

  const cancelSpeech = useCallback(() => {
    setVolume(0);
    if (botAudio) {
      botAudio.pause();
      botAudio.currentTime = 0;
    }
    setBotTalking(false);
  }, [botAudio]);

  const startSpeech = useCallback(
    (
      currentChunk = currentTextChunk,
      chunks = textChunks,
      index = currentIndex
    ) => {
      setVolume(1);
      const speechText = chunks[currentChunk].slice(index);
      synthesizeSpeech(speechText);
      setBotTalking(true);
    },
    // eslint-disable-next-line
    [isBotTalking, currentIndex, currentTextChunk, textChunks]
  );

  useEffect(() => {
    const chunkedText = chunkTextByPeriods(text, 250);
    setTextChunks(chunkedText);
    setCurrentTextChunk(0);
    setCurrentIndex(0);
    setCurrentText("");
  }, [text, chunkTextByPeriods]);

  const continueRenderingLetters = useCallback(
    (toggleSpeaker) => {
      cancelSpeech();
      setCurrentText("");
      setCurrentIndex(0);

      const nextChunk = currentTextChunk + 1;
      setCurrentTextChunk(nextChunk);
      setTimeout(() => {
        if (toggleSpeaker) {
          startSpeech(nextChunk, textChunks, 0);
        }
      }, 300);
    },
    [currentTextChunk, textChunks, cancelSpeech, startSpeech]
  );

  useImperativeHandle(ref, () => ({
    continueRenderingLetters,
    toggleVolume,
    restartSpeech,
    cancelSpeech,
  }));

  const restartSpeech = useCallback(() => {
    cancelSpeech();
    setTimeout(() => {
      setVolume(1);
      startSpeech(currentTextChunk, textChunks, 0);
      setBotTalking(true);
    }, 50);
  }, [cancelSpeech, startSpeech]);

  const toggleVolume = () => {
    if (volume === 0) {
      setVolume(1);
      if (!isBotTalking) {
        startSpeech();
      }
    } else {
      cancelSpeech();
      setBotTalking(false);
    }
  };

  const renderLetterText = useCallback(() => {
    if (currentTextChunk >= textChunks.length) return;
    const currentChunk = textChunks[currentTextChunk];
    setCurrentText((prevText) => prevText + currentChunk[currentIndex]);
    setCurrentIndex((prevIndex) => prevIndex + 1);
  }, [currentTextChunk, textChunks, currentIndex]);

  useEffect(() => {
    let timeout;

    if (textChunks.length === 0) return;

    const currentChunk = textChunks[currentTextChunk];
    if (!currentChunk) return;

    if (currentIndex < currentChunk.length) {
      timeout = setTimeout(renderLetterText, delay);
    } else if (currentTextChunk < textChunks.length - 1) {
      onContinueStatusChange();
      //cancelSpeech();
    } else if (infinite) {
      timeout = setTimeout(() => {
        setCurrentTextChunk(0);
        setCurrentIndex(0);
        setCurrentText("");
      }, delay);
    } else if (onCompleted) {
      onCompleted();
    }

    return () => clearTimeout(timeout);
  }, [
    currentIndex,
    currentTextChunk,
    delay,
    infinite,
    onCompleted,
    onContinueStatusChange,
    renderLetterText,
    textChunks,
  ]);

  return <span>{currentText}</span>;
});

export default TypingText;
