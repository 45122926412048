import React, { useState } from 'react';
import CustomButton from '../../../components/CustomButton';
import Lottie from 'react-lottie';
import { lottieAvatars } from '../../../utils/constants';

function AvatarSelector({ onContinue }) {
  const [selectedAvatar, setSelectedAvatar] = useState('avatar-woman-1');

  const handleAvatarSelect = (avatarId) => {
    setSelectedAvatar(avatarId);
  };

  return (
    <div className="flex flex-col items-center justify-center gap-4 md:gap-8 p-4 md:p-6 bg-white rounded-lg shadow-lg w-[95%] md:w-auto max-w-2xl">
      <h2 className="text-xl md:text-2xl font-bold text-primary">Choose Your Avatar</h2>
      
      {/* Large preview avatar - animated */}
      <div className="w-48 h-48 md:w-64 md:h-64">
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: require(`../../../sources/lotties/avatars/${lottieAvatars[selectedAvatar]}`),
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height="100%"
          width="100%"
        />
      </div>

      {/* Avatar options grid - static */}
      <div className="w-full max-h-[240px] md:max-h-[288px] overflow-y-auto p-2 custom-scrollbar">
        <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-4">
          {Object.keys(lottieAvatars).map((avatarId) => (
            <button
              key={avatarId}
              onClick={() => handleAvatarSelect(avatarId)}
              className={`w-20 h-20 md:w-24 md:h-24 rounded-lg overflow-hidden border-2 transition-all p-2
                ${selectedAvatar === avatarId ? 'border-primary scale-110 bg-primary/10' : 'border-gray-200 hover:border-primary'}`}
            >
              <Lottie
                options={{
                  loop: false,
                  autoplay: false,
                  animationData: require(`../../../sources/lotties/avatars/${lottieAvatars[avatarId]}`),
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height="100%"
                width="100%"
                isStopped={true}
              />
            </button>
          ))}
        </div>
      </div>

      <CustomButton
        buttonColor="primary"
        buttonType="solid"
        buttonSize="lg"
        onClick={() => onContinue(selectedAvatar)}
      >
        Continue
      </CustomButton>
    </div>
  );
}

export default AvatarSelector; 