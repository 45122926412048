import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { 
  ChatBubbleLeftRightIcon,
  ChevronDoubleRightIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  ChartPieIcon,
  Bars3Icon,
  XMarkIcon
} from "@heroicons/react/24/outline";

import LogoutIcon from "../../../sources/icons/LogoutIcon.js";
import CustomButton from '../../../components/CustomButton';
import { store } from "../../../reducers/store";
import { setAuthData } from "../../../reducers/auth.reducer";
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const navigate = useNavigate();
  const { userRole } = useSelector((state) => state.authReducer);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  }
  
  const onLogOut = () => {
    localStorage.removeItem("user-token");
    store.dispatch(
      setAuthData({
        userId: null,
        userToken: null,
        userName: null,
        firstName: null,
        lastName: null,
        email: null,
        userRole: null,
        avatar: null
      })
    );
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <div className="lg:hidden">
        <button
          className="fixed z-50 top-4 left-4"
          onClick={toggleSidebar}
        >
          {isOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
      </div>
      <aside
        className={`${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 fixed lg:sticky top-0 left-0 z-40 h-full w-64 transition-transform duration-300 ease-in-out bg-white lg:border-r-2 lg:border-stone-300 overflow-y-auto`}
      >
        <div className="flex flex-col h-full">
          <div className="px-6 py-4 border-b border-stone-200">
            <h1 className="text-primary text-2xl font-semibold">Kanka Bot</h1>
          </div>

          <nav className="flex-1 px-4 py-6">
            <ul className="space-y-2 text-base font-medium text-stone-500">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex items-center px-2 py-2 rounded-lg transition-colors ${
                      isActive ? "text-black bg-stone-100" : "hover:text-black hover:bg-stone-50"
                    }`
                  }
                  to="home"
                >
                  <HomeIcon className="h-5 w-5" />
                  <span className="ml-3">Home</span>
                </NavLink>
              </li>
              {userRole === "admin" && (
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      `flex items-center px-2 py-2 rounded-lg transition-colors ${
                        isActive ? "text-black bg-stone-100" : "hover:text-black hover:bg-stone-50"
                      }`
                    }
                    to="supervisors"
                  >
                    <UsersIcon className="h-5 w-5" />
                    <span className="ml-3">Supervisors</span>
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex items-center px-2 py-2 rounded-lg transition-colors ${
                      isActive ? "text-black bg-stone-100" : "hover:text-black hover:bg-stone-50"
                    }`
                  }
                  to="supervisees"
                >
                  <UserGroupIcon className="h-5 w-5" />
                  <span className="ml-3">Supervisees</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `flex items-center px-2 py-2 rounded-lg transition-colors ${
                      isActive ? "text-black bg-stone-100" : "hover:text-black hover:bg-stone-50"
                    }`
                  }
                  to="analytics/conversations"
                >
                  <ChartPieIcon className="h-5 w-5" />
                  <span className="ml-3">Analytics</span>
                </NavLink>
              </li>
              <div className="pl-4 mt-2 space-y-1">
                <NavLink
                  className={({ isActive }) =>
                    `flex items-center px-2 py-2 text-sm rounded-lg transition-colors ${
                      isActive ? "text-black bg-stone-100" : "hover:text-black hover:bg-stone-50"
                    }`
                  }
                  to="analytics/conversations"
                >
                  <ChatBubbleLeftRightIcon className="h-4 w-4" />
                  <span className="ml-3">Conversations</span>
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    `flex items-center px-2 py-2 text-sm rounded-lg transition-colors ${
                      isActive ? "text-black bg-stone-100" : "hover:text-black hover:bg-stone-50"
                    }`
                  }
                  to="analytics/supervisees"
                >
                  <ChevronDoubleRightIcon className="h-4 w-4" />
                  <span className="ml-3">Supervisees</span>
                </NavLink>
              </div>
            </ul>
          </nav>

          <div className="px-4 py-4 border-t border-stone-200">
            <CustomButton
              onClick={onLogOut}
              buttonSize="lg"
              buttonType="outline"
              buttonColor="danger"
              customStyle="w-full"
            >
              <span className="flex items-center justify-center">
                <LogoutIcon height={20} width={20} color={"black"} />
                <span className="ml-2">Logout</span>
              </span>
            </CustomButton>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
