/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import NewOffenderModal from "./NewOffenderModal";
import { toast } from "react-toastify";
import { makeGetRequest, makePostRequest } from "../../../utils/makeRequest";

function OffenderPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [offenders, setOffenders] = useState([]);
  const [isOffendersFetched, setOffendersFetched] = useState(false)
  const [isUserFetched, setUserFetched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const moment = require("moment")
  const [initialFormData, setInitialFormData] = useState(null);

  useEffect(() => {
    if(!isOffendersFetched) {
      loadOffendersData();
      setOffendersFetched(true)
    }

    if(!isUserFetched) {
      setUserFetched(true)
    }
    // eslint-disable-next-line
  }, []);

  const loadOffendersData = () => {
    makeGetRequest({
      path: `/api/user/get-offenders`, config: {
        headers: {
          "Content-Type": "application/json",
        },
      }, navigate, location})
      .then((res) => {
        let arr = res.data.data.offenders
        const formattedOffenders = arr.map(offender => {
          if (offender.hasOwnProperty("createdAt")) {
            const formattedOffender = { ...offender, fullName: `${offender.lastName}, ${offender.firstName}`, "createdAt": offender.createdAt ? moment(offender.createdAt).format("MM/DD/YYYY HH:mm") : "N/A"}
            return formattedOffender
          }
 
          return offender
        })
        
        setOffenders(formattedOffenders);
      })
  };

  const handleCreateNewOffender = (event, setUserDetails, userDetails) => {
    event.preventDefault();
    const loadingID = toast.loading("Please wait...");
    makePostRequest({
      path: `/api/user/add-offender`,
      body: userDetails,
      config: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      navigate,
      location,
    })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.success) {
          toast.update(loadingID, {
            render: "Successfully added new supervisee",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          closeModal();
          setUserDetails({});
          loadOffendersData();
        } else {
          toast.update(loadingID, {
            render: "something went wrong. try again later.",
            type: "error",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
        }
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      key: "status",
      label: "Status",
      render: (_, row) => (
        <div 
          className="flex items-center cursor-pointer" 
          onClick={() => {
            if (row.createdAt === "N/A") {
              const dob = row.dateOfBirth;
              const dobYear = dob ? new Date(dob).getFullYear() : "";
              const suggestedUsername = `${row.firstName.toLowerCase()}${row.lastName.toLowerCase()}${dobYear}`;
              
              setIsModalOpen(true);

              setInitialFormData({
                firstName: row.firstName,
                lastName: row.lastName,
                username: suggestedUsername,
              });
            }
          }}
        >
          <div
            className={`w-2.5 h-2.5 rounded-full ${
              row.createdAt !== "N/A" ? "bg-green-500" : "bg-gray-300"
            }`}
          />
          <span className="ml-2 text-sm text-gray-600">
            {row.createdAt !== "N/A" ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      key: "username",
      label: "Username",
    },
    {
      key: "fullName",
      label: "Name",
    },
    {
      key: "dateOfBirth",
      label: "Date of Birth",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "createdAt",
      label: "Joined On",
    },
  ];

  return (
    <div className="w-full h-full px-5">
      <h1 className="text-3xl mt-4 font-semibold text-left">Supervisees</h1>

      <div className="mt-5 rounded-lg">
        <CustomTable skeleton={true} data={offenders} columns={columns} searchable={true} />
      </div>

      <NewOffenderModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          setTimeout(() => {
            setInitialFormData(null);
          }, 3000);
        }}
        handleCreateNewOffender={handleCreateNewOffender}
        initialData={initialFormData}
      />
    </div>
  );
}

export default OffenderPage;
