import React, { useEffect, useState } from "react";
import {
  Field,
  Popover,
  PopoverButton,
  PopoverPanel,
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxButton,
} from "@headlessui/react";
import {
  UserIcon,
  EllipsisHorizontalIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import CustomModal from "../../../../components/CustomModal";
import CustomButton from "../../../../components/CustomButton";
import CheckmarkIcon from "../../../../sources/icons/CheckmarkIcon.svg";
import { store } from "../../../../reducers/store";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../../../utils/constants";

function Sidebar({ isRightContentVisible, sidebarContent }) {
  const [assignedOffenders, setAssignedOffenders] = useState([]);
  const [selectedOffender, setSelectedOffender] = useState({});
  const [query, setQuery] = useState("");
  const [otherOfficerMetrics, setOtherOfficerMetrics] = useState({
    activeOffenders: null,
    totalOffenders: null,
  });

  const [showAssignToolModal, setShowAssignToolModal] = useState(false);
  const [selectedTool, setSelectedTool] = useState({});

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [treatmentTools, setTreatmentTools] = useState([]);

  const firstName = store.getState().authReducer.firstName;
  const lastName = store.getState().authReducer.lastName;

  const fetchAssignedOffenders = async () => {
    const { authReducer } = store.getState();
    return await axios.get(`${baseUrl}/api/user/get-assigned-offenders`, {
      headers: {
        "Content-Type": "application/json",
        authorization: authReducer.userToken,
      },
    });
  };

  const fetchTreatmentTools = async () => {
    const { authReducer } = store.getState();
    return await axios.get(`${baseUrl}/api/conversation/list-treatment-tools`, {
      headers: {
        "Content-Type": "application/json",
        authorization: authReducer.userToken,
      },
    });
  };

  useEffect(() => {
    setSelectedTool(treatmentTools[0]);
  }, [treatmentTools]);

  useEffect(() => {
    const promises = [fetchAssignedOffenders(), fetchTreatmentTools()];
    Promise.all(promises)
      .then((results) => {
        results[0]?.data?.success &&
          setAssignedOffenders(results[0].data.offenders);
        results[0]?.data?.success &&
          setOtherOfficerMetrics({
            activeOffenders: results[0].data.offenders.length,
            totalOffenders: results[0].data.offenders.length,
          });

        results[1]?.data?.success && setTreatmentTools([ {name: "Auto Detect", flowId: "user-returning.flow.json"}, ...results[1].data.data]);
      })
      .catch((error) => {
        console.log(error);
        toast("something went wrong", { type: "error" });
      });
  }, []);

  const filteredTools =
    query === ""
      ? treatmentTools
      : treatmentTools.filter((tool) => {
          return tool.name.toLowerCase().includes(query.toLowerCase());
        });

  const assignTool = () => {
    const { authReducer } = store.getState();
    if (!title || !description || !selectedTool) {
      toast("Please fill all the details.", { type: "error" });
      return;
    }
    axios
      .post(
        `${baseUrl}/api/conversation/assign-a-tool`,
        {
          toolName: selectedTool.name,
          offenderId: selectedOffender.userId,
          conversationTitle: title,
          conversationDescription: description,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: authReducer.userToken,
          },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          setShowAssignToolModal(false);
          setTitle("");
          setDescription("");
          setSelectedTool(treatmentTools[0]);
          toast("Assigned a tool successfully!", { type: "success" });
        }
      })
      .catch((res) => {
        toast(res?.data?.message ?? "Something went wrong", { type: "error" });
      });
  };


  return (
    <section
      id="right-content"
      className={`top-0 right-0 flex flex-col h-full bg-white transform transition-transform duration-300 ease-in-out 
        ${
          isRightContentVisible
            ? "translate-x-0 w-72 2xl:w-96"
            : "translate-x-full w-0"
        }`}
    >
      {sidebarContent === "Profile" && (
        <div className="flex flex-col w-full p-6">
          <div
            id="profile-details"
            className="min-w-full bg-[#f1f0f0] py-3 xl:p-6 rounded-xl"
          >
            <div className="flex flex-col min-w-full">
              <div id="logo-and-name" className="flex flex-col items-center">
                <div className="p-2 mb-1 border-4 border-white rounded-full">
                  <UserIcon className="h-8 w-8" />
                </div>
                <p>
                  {firstName} {lastName}
                </p>
                <p className="text-xs text-stone-500">(Officer)</p>
              </div>
              <div id="offender-metrics" className="mt-2 flex">
                <div id="active-offenders" className="basis-1/2 p-3">
                  <p className="text-sm">Active Supervisees</p>
                  <p className="text-2xl">
                    {otherOfficerMetrics.activeOffenders}
                  </p>
                </div>
                <div id="total-offenders" className="basis-1/2 p-3 text-right">
                  <p className="text-sm">Assigned Supervisees</p>
                  <p className="text-2xl">
                    {otherOfficerMetrics.totalOffenders}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            id="assigned-offenders-list"
            className="flex flex-col flex-grow overflow-hidden mt-4"
          >
            <div className="offenders-list-header flex justify-between mb-3">
              <p className="text-stone-300">----</p>
              <p>Assigned Supervisees</p>
              <p className="text-stone-300">----</p>
            </div>
            <Popover>
              <ul className="overflow-y-scroll flex-grow">
                {assignedOffenders.map((offender) => {
                  return (
                    <li
                      key={offender.userId}
                      className="flex justify-between py-3"
                    >
                      <div className="flex grow gap-2">
                        <UserIcon className="h-6 w-6" />
                        <p className="border-b-2 grow pb-3">
                          {offender.firstName} {offender.lastName}
                        </p>
                      </div>
                      <PopoverButton
                        onClick={() => {
                          setSelectedOffender(offender);
                        }}
                        className="pb-3 h-6 w-6 rounded-full data-[focus]:bg-[#f1f0f0] data-[active]:bg-[#f1f0f0]"
                      >
                        <EllipsisHorizontalIcon className="h-6 w-6" />
                      </PopoverButton>
                    </li>
                  );
                })}
              </ul>
              <PopoverPanel
                transition
                anchor="bottom end"
                className="divide-y divide-white/5 rounded-lg bg-[#f1f0f0] text-sm/6 transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0 p-2"
              >
                <div className="">
                  <button onClick={() => setShowAssignToolModal(true)}>
                    Assign Tool
                  </button>
                </div>
              </PopoverPanel>
            </Popover>
          </div>
          <CustomModal
            isOpen={showAssignToolModal}
            onClose={() => setShowAssignToolModal(false)}
            titleStyle={"w-full flex items-center justify-left mb-6"}
            modalStyle={"w-full md:w-2/3 lg:w-1/3 sm:h-1/2 md:h-min"}
            title={"Assign a Tool"}
          >
            <div className="h-full w-full flex flex-col">
              <p className="flex gap-2 mb-2 self-end">
                <UserIcon className="h-6 w-6" />
                <span className="h-6 pt-0.5">
                  {selectedOffender.firstName} {selectedOffender.lastName}
                </span>
              </p>

              <input
                type="text"
                placeholder="My new tool"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full border rounded-md p-3 mb-4"
                style={{ fontSize: "14px" }}
              />

              <textarea
                placeholder="My new tool that helps me for..."
                value={description}
                maxLength={150}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full border rounded-md p-3 mb-4 h-32"
                style={{ fontSize: "14px" }}
                draggable="false"
              />

              <Field className={"flex gap-2 w-full mb-4"}>
                <Combobox
                  value={selectedTool}
                  onChange={setSelectedTool}
                  onClose={() => setQuery("")}
                >
                  <div className="relative w-full">
                    <ComboboxInput
                      aria-label="Tool"
                      displayValue={(tool) => tool?.name}
                      onChange={(event) => setQuery(event.target.value)}
                      className="w-full rounded-lg border-none bg-[#f1f0f0] py-1.5 pr-8 pl-3 text-sm/6 text-black
                  focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    />
                    <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
                      <ChevronDownIcon className="size-4 fill-white/60 group-data-[hover]:fill-white" />
                    </ComboboxButton>
                  </div>
                  <ComboboxOptions
                    anchor="bottom"
                    className="
                w-[var(--input-width)] rounded-lg border border-white/5 bg-[#f1f0f0] p-1 [--anchor-gap:var(--spacing-1)] empty:invisible
                transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0
              "
                  >
                    {filteredTools.map((tool) => (
                      <ComboboxOption
                        key={tool.flowId}
                        value={tool}
                        className="data-[focus]:bg-blue-100 py-2 border-b text-sm rounded-lg px-2"
                      >
                        {tool.name}
                      </ComboboxOption>
                    ))}
                  </ComboboxOptions>
                </Combobox>
              </Field>

              <div className="">
                <div className="flex justify-end">
                  <CustomButton
                    onClick={assignTool}
                    buttonSize="md"
                    buttonType="solid"
                    buttonColor="primary"
                    customStyle=""
                  >
                    <span className="flex flex-row gap-2 items-center justify-center text-md">
                      <span className="pt-1.5">Assign</span>
                      <img
                        src={CheckmarkIcon}
                        alt="Search Icon"
                        height={20}
                        width={20}
                        className=""
                      />
                    </span>
                  </CustomButton>
                </div>
              </div>
            </div>
          </CustomModal>
        </div>
      )}
      {sidebarContent === "Conversation" && (
        <div>
          <div
            className={`h-full w-full bg-gray-300 relative`}
            id="conversation-chat-container"
          ></div>
        </div>
      )}
    </section>
  );
}

export default Sidebar;
