import { makePostRequest } from "./makeRequest";

const addCheckpoint = async ({ nodeId, flowId, bpConversationId, name, status, navigate, location }) => {
  await makePostRequest({
    path: '/api/conversation/add-checkpoint',
    body: { nodeId, flowId, bpConversationId, name, status },
    navigate, location
  })
}

export default addCheckpoint;
