import React, { useState, useMemo } from "react";
import {
  ChevronDown,
  ChevronUp,
  ChevronsUpDown,
  ChevronLeft,
  ChevronRight,
  Search,
} from "lucide-react";
import Lottie from "react-lottie";
import ghostAnimation from "../sources/lotties/ghostAnimation.json";

const CustomTable = ({
  data = [],
  columns = [],
  itemsPerPage = 10,
  searchable = false,
  sortable = true,
  headerShown = true,
  skeleton = false,
  leftButtons = null,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Sort function
  const sortData = (data, sortConfig) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
  };

  // Handle sort
  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Filter function
  const filteredData = useMemo(() => {
    if (!searchQuery) return data;

    return data.filter((item) =>
      Object.values(item).some((value) => {
        if (value) {
          return value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        }
      }
      )
    );
  }, [data, searchQuery]);

  // Sort the filtered data
  const sortedData = useMemo(
    () => sortData(filteredData, sortConfig),
    [filteredData, sortConfig]
  );

  // Pagination
  const totalPages = Math.ceil(sortedData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

  // Get sort icon
  const getSortIcon = (columnKey) => {
    if (sortConfig.key !== columnKey)
      return <ChevronsUpDown className="w-4 h-4 ml-1" />;
    return sortConfig.direction === "ascending" ? (
      <ChevronUp className="w-4 h-4 ml-1" />
    ) : (
      <ChevronDown className="w-4 h-4 ml-1" />
    );
  };

  return (
    <div className="w-full bg-transparent">
      {/* Table Controls */}
      {(searchable || leftButtons) && (
        <div className="mb-4 flex justify-between items-center">
          {/* Left side buttons */}
          <div className="flex gap-2">
            {leftButtons}
          </div>

          {/* Search Bar */}
          {searchable && (
            <div className="relative w-64">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
              />
            </div>
          )}
        </div>
      )}

      {/* Table */}
      <div className={`rounded-xl ${skeleton && "border"} bg-transparent`}>
        <table className="w-full text-sm">
          <thead>
            <tr className={`${skeleton && "border-b"} bg-gray-50`}>
              {headerShown &&
                columns.map((column) => (
                  <th
                    key={column.label}
                    className="px-4 py-3 text-left font-medium text-gray-500"
                  >
                    <div
                      className={`flex items-center ${
                        sortable ? "cursor-pointer" : ""
                      }`}
                      onClick={() => sortable && requestSort(column.key)}
                    >
                      {column.label}
                      {sortable && getSortIcon(column.key)}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData.map((row, index) => (
              <tr
                key={index}
                className={`${
                  skeleton && "border-b"
                } transition-colors hover:bg-gray-50`}
              >
                {columns.map((column) => (
                  <td key={Math.random()} className="px-4 py-3 text-gray-700">
                    {column.render
                      ? column.render(row[column.key], row)
                      : row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>

        {paginatedData.length === 0 && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <div className="w-48 h-48">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: ghostAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
              />
            </div>
            <p className="mt-2 text-xl">No data found for this date range.</p>
          </div>
        )}
      </div>

      {/* Pagination */}
      <div className="flex items-center justify-between mt-4">
        {paginatedData.length !== 0 && (
          <React.Fragment>
            <div className="text-sm text-gray-500">
              Showing {startIndex + 1} to{" "}
              {Math.min(startIndex + itemsPerPage, sortedData.length)} of{" "}
              {sortedData.length} entries
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => {
                  if (startIndex === sortedData.length) return;
                  setCurrentPage((prev) => Math.max(prev - 1, 1));
                }}
                disabled={currentPage === 1}
                className="p-2 rounded-md border hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronLeft className="w-4 h-4" />
              </button>
              <button
                onClick={() => {
                  if (startIndex === sortedData.length) return;
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages));
                }}
                disabled={currentPage === totalPages}
                className="p-2 rounded-md border hover:bg-gray-50 disabled:opacity-50"
              >
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CustomTable;
