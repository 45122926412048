import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import {
  Field,
  Label,
  Select,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  CalendarIcon,
  ChatBubbleOvalLeftIcon,
  ClockIcon,
  UserIcon,
  XMarkIcon,
  ChatBubbleBottomCenterIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { store } from "../../../reducers/store";
import { baseUrl, botpressUrl } from "../../../utils/constants";
import CustomTable from "../../../components/CustomTable";
import { ChevronDownIcon as ChevronDownIconOutlined } from "@heroicons/react/16/solid";
import Sidebar from "./components/Sidebar";
import "./HomePage.css";
import { makeGetRequest } from "../../../utils/makeRequest";
import { useLocation, useNavigate } from "react-router-dom";
import ViewTool from "../../dashboard/Components/ViewTool";

const Home = () => {
  const [lastActiveConversations, setLastActiveConversations] = useState([]);
  const [isRightContentVisible, setIsRightContentVisible] = useState(true);
  const [sidebarContent, setSidebarContent] = useState("Profile");
  const navigate = useNavigate();
  const location = useLocation();
  const [isViewToolOpen, setIsViewToolOpen] = useState(false);
  const [toolImage, setToolImage] = useState(null);
  const [selectedToolId, setSelectedToolId] = useState(null);

  const toggleProfileBar = () => {
    setSidebarContent("Profile");
    setIsRightContentVisible(!isRightContentVisible);
  };

  const toggleConversationBar = (offenderId, conversationId) => {
    if (isRightContentVisible) {
      setIsRightContentVisible(false);
    }

    setTimeout(() => {
      setSidebarContent("Conversation");
      setTimeout(() => {
        initializeChatBot(offenderId, {
          conversationId: conversationId,
        });
      }, 300);
      setIsRightContentVisible(true);
    }, 400);
  };

  const firstName = store.getState().authReducer.firstName;

  const computeAnalysisePeriodStartAndEnd = (analysisPeriod) => {
    let analysisPeriodStart, analysisPeriodEnd;
    analysisPeriodEnd = moment().format("YYYY-MM-DD HH:mm");
    if (analysisPeriod === "3d") {
      analysisPeriodStart = moment().subtract(3, "days").format("YYYY-MM-DD");
    } else if (analysisPeriod === "1w") {
      analysisPeriodStart = moment().subtract(1, "week").format("YYYY-MM-DD");
    } else if (analysisPeriod === "1m") {
      analysisPeriodStart = moment().subtract(1, "month").format("YYYY-MM-DD");
    } else if (analysisPeriod === "1y") {
      analysisPeriodStart = moment().subtract(1, "year").format("YYYY-MM-DD");
    } else {
      analysisPeriodStart = moment().subtract(3, "months").format("YYYY-MM-DD");
    }
    return { analysisPeriodStart, analysisPeriodEnd };
  };

  const updateAnalysisPeriod = (event) => {
    const analysisPeriod = event.target.value;

    fetchLastActiveConversations(analysisPeriod)
      .then((res) => {
        res?.data?.success &&
          setLastActiveConversations(res.data.latestConversationDetails);
      })
      .catch((error) => {
        console.log(error);
        toast("something went wrong", { type: "error" });
      });
  };

  useEffect(() => {
    fetchLastActiveConversations("1w")
      .then((res) => {
        res?.data?.success &&
          setLastActiveConversations(res.data.latestConversationDetails);
      })
      .catch((error) => {
        console.log(error);
        toast("something went wrong", { type: "error" });
      });

    // eslint-disable-next-line
  }, []);

  async function fetchLastActiveConversations(analysisPeriod) {
    const { authReducer } = store.getState();
    const { analysisPeriodStart, analysisPeriodEnd } =
      computeAnalysisePeriodStartAndEnd(analysisPeriod);

    return await axios.get(
      `${baseUrl}/api/user/get-latest-conversations-for-officer`,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: authReducer.userToken,
        },
        params: {
          analysisPeriodStart,
          analysisPeriodEnd,
          limit: 30,
        },
      }
    );
  }

  const columns = [
    {
      label: "Conversation",
      key: "conversationTitle",
      render: (title, row) => {
        const iconColor = row.isActive ? "fill-secondary" : "fill-[#00b45c]";
        const iconBackground = row.isActive ? "bg-secondary" : "bg-[#00b45c]";
        return (
          <div className="flex items-center justify-start">
            <div className={`${`${iconBackground} border rounded-full p-2`}`}>
              <ChatBubbleOvalLeftIcon
                className={`${iconColor} h-5 w-5 text-white`}
              />
            </div>
            <p className="pl-2 mt-1">{title}</p>
          </div>
        );
      },
    },
    { label: "Name", key: "fullName" },
    {
      label: "Status",
      key: "isActive",
      render: (status) => {
        return (
          <div className="flex gap-1 items-center justify-start flex">
            <div
              className={`h-2 w-2 rounded-full ${
                status ? "bg-secondary" : "bg-[#00b45c]"
              }`}
            ></div>
            <p>{status ? "In Progress" : "Completed"}</p>
          </div>
        );
      },
    },
    {
      label: "Last Updated",
      key: "updatedAt",
      icon: ClockIcon,
      fillIcon: false,
      showIconBackground: false,
    },
    {
      label: "Options",
      fillIcon: false,
      showIconBackground: false,
      render: (_, row) => {
        return (
          <div className="">
            <Menu>
              <MenuButton className="inline-flex items-center justify-left text-white gap-2 rounded-md bg-[#6B7DBB] py-1 pl-3 pr-6 text-sm/6 font-semibold shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 hover:text-white data-[open]:bg-gray-600 data-[focus]:outline-1 data-[focus]:outline-white">
                Select
                <ChevronDownIconOutlined className="size-4 fill-white/60" />
              </MenuButton>

              <MenuItems
                transition
                anchor="bottom end"
                className="w-52 origin-top-right rounded-xl border border-white/5 bg-white p-1 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
              >
                <MenuItem>
                  <button
                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 bg-[#ffff] text-black data-[focus]:bg-[#6B7DBB] data-[focus]:text-[#fff]"
                    onClick={() => {
                      if (!row.isActive) {
                        setSelectedToolId(row.conversationId);
                        fetchToolImage(row.conversationId);
                        setIsViewToolOpen(true);
                      } else {
                        toast.info("Conversation is still in progress");
                      }
                    }}
                  >
                    <ClipboardDocumentCheckIcon className="h-4 w-4" />
                    View Tool
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 bg-[#ffff] text-black data-[focus]:bg-[#6B7DBB] data-[focus]:text-[#fff]"
                    onClick={() => {
                      toggleConversationBar(row.userId, row.conversationId);
                    }}
                  >
                    <ChatBubbleBottomCenterIcon className="h-4 w-4" />
                    View Conversation
                  </button>
                </MenuItem>
              </MenuItems>
            </Menu>
          </div>
        );
      },
    },
  ];

  const rows = lastActiveConversations.map((conversation) => ({
    ...conversation,
    fullName: `${conversation.firstName} ${conversation.lastName}`,
    updatedAt: moment(conversation.updatedAt).fromNow().toString(),
  }));

  const initializeChatBot = async (userId, chatOptions = {}) => {
    try {
      const rootLink = window.location.origin;
      //  maxMessagesHistory: 500,
      window.botpressWebChat.init(
        {
          host: botpressUrl,
          userId: userId,
          botId: "odrc",
          hideWidget: true,
          showPoweredBy: false,
          extraStylesheet: rootLink + "/styles/chatbotOfficer.css",
          botName: "Kanka Bot",
          enableTranscriptDownload: false,
          showConversationsButton: false,
          viewMode: "Fullscreen",
          ...chatOptions,
        },
        "#conversation-chat-container"
      );
    } catch (error) {
      console.log("script error:", error);
    }
  };

  const fetchToolImage = async (conversationId) => {
    const response = await makeGetRequest({
      path: `/api/conversation/view-tool`,
      config: {
        params: { conversationId },
        headers: {
          "Content-Type": "application/json",
        },
      },
      navigate,
      location,
    });

    if (response.data.success && response.data.data.imageUrls.length > 0) {
      setToolImage(response.data.data.imageUrls[0]);
    } else {
      setToolImage(null);
    }
  };

  return (
    <div
      id="admin-home-container"
      className="flex flex-nowrap flex-row min-w-full min-h-dvh justify-between"
    >
      <div className="grow">
        <div className="heading flex justify-between m-6">
          <div id="title" className="mt-10 lg:mt-0">
            <p className="text-3xl font-bold">Hello, {firstName}</p>
            <p className="text-stone-500">
              Here is an overview about what happened today
            </p>
          </div>
          <div className="flex gap-2 max-h-fit">
            <p className="max-h-fit pt-1">{moment().format("D MMM, YYYY")}</p>
            <CalendarIcon className="h-7 w-7 rounded-full bg-[#f1f0f0] p-1" />
            <button
              className={`${
                isRightContentVisible ? "fixed right-6 top-6" : "relative"
              } z-10 ${
                sidebarContent === "Profile" ? "2xl:hidden" : ""
              } max-h-fit flex`}
              onClick={toggleProfileBar}
            >
              {isRightContentVisible ? (
                <XMarkIcon className="h-8 w-8 p-1 rounded-full bg-[#f1f0f0]" />
              ) : (
                <UserIcon className="h-7 w-7 p-1 rounded-full bg-[#f1f0f0]" />
              )}
            </button>
          </div>
        </div>
        <section id="main-content" className="xl:basis-3/4 px-6 xl:py-6 grow">
          <div id="last-active-conversations">
            <div className="table-header flex justify-between mb-3">
              <p className="font-bold pl-2">Last Active Conversations</p>
              <Field>
                <Label>Period: </Label>
                <Select
                  name="analysisPeriod"
                  aria-label="Analysis Period"
                  defaultValue={"1w"}
                  onChange={updateAnalysisPeriod}
                  className={`border rounded-lg py-2 px-1 data-[hover]:shadow data-[focus]:bg-blue-100`}
                >
                  <option value="3d">3 Days</option>
                  <option value="1w">1 Week</option>
                  <option value="1m">1 Month</option>
                </Select>
              </Field>
            </div>

            <div className="container min-w-full grow">
              <CustomTable
                data={rows}
                columns={columns}
                itemsPerPage={5}
                skeleton={false}
                headerShown={true}
                sortable={true}
              />
            </div>
          </div>
        </section>
      </div>
      <Sidebar
        isRightContentVisible={isRightContentVisible}
        sidebarContent={sidebarContent}
      />
      {isViewToolOpen && (
        <ViewTool
          conversationId={selectedToolId}
          imageUrl={toolImage}
          onClose={() => {
            setIsViewToolOpen(false);
            setToolImage(null);
          }}
        />
      )}
    </div>
  );
};

export default Home;
