import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "../../../components/CustomTable";
import { toast } from "react-toastify";
import { makeGetRequest, makePostRequest } from "../../../utils/makeRequest";

const Supervisor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [supervisors, setSupervisors] = useState([]);
  const [isSupervisorsFetched, setSupervisorsFetched] = useState(false)
  const [isUserFetched, setUserFetched] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const moment = require("moment")
  const [initialFormData, setInitialFormData] = useState(null);

  useEffect(() => {
    if(!isSupervisorsFetched) {
      loadSupervisorsData();
      setSupervisorsFetched(true)
    }

    if(!isUserFetched) {
      setUserFetched(true)
    }
    // eslint-disable-next-line
  }, []);

  const loadSupervisorsData = () => {
    makeGetRequest({
      path: `/api/user/get-supervisors`, config: {
        headers: {
          "Content-Type": "application/json",
        },
      }, navigate, location})
      .then((res) => {
        let arr = res.data.data.supervisors
        const formattedSupervisors = arr.map(supervisor => {
            const formattedSupervisor = { ...supervisor, fullName: `${supervisor.lastName}, ${supervisor.firstName}`, "createdAt": supervisor.createdAt ? moment(supervisor.createdAt).format("MM/DD/YYYY HH:mm") : "N/A"}
            return formattedSupervisor
        })
        
        setSupervisors(formattedSupervisors);
      })
  };

  const handleCreateNewSupervisor = (event, setUserDetails, userDetails) => {
    event.preventDefault();
    const loadingID = toast.loading("Please wait...");
    makePostRequest({
      path: `/api/user/add-supervisor`,
      body: userDetails,
      config: {
        headers: {
          "Content-Type": "application/json",
        },
      },
      navigate,
      location,
    })
      .then((res) => {
        return res.data;
      })
      .then((data) => {
        if (data.success) {
          toast.update(loadingID, {
            render: "Successfully added new supervisor",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
          closeModal();
          setUserDetails({});
          loadSupervisorsData();
        } else {
          toast.update(loadingID, {
            render: "something went wrong. try again later.",
            type: "error",
            isLoading: false,
            autoClose: 2000,
            closeOnClick: true,
          });
        }
      });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      key: "status",
      label: "Status",
      render: (_, row) => (
        <div 
          className="flex items-center cursor-pointer" 
          onClick={() => {
            if (row.createdAt === "N/A") {
              const dob = row.dateOfBirth;
              const dobYear = dob ? new Date(dob).getFullYear() : "";
              const suggestedUsername = `${row.firstName.toLowerCase()}${row.lastName.toLowerCase()}${dobYear}`;
              
              setIsModalOpen(true);

              setInitialFormData({
                firstName: row.firstName,
                lastName: row.lastName,
                username: suggestedUsername,
              });
            }
          }}
        >
          <div
            className={`w-2.5 h-2.5 rounded-full ${
              row.createdAt !== "N/A" ? "bg-green-500" : "bg-gray-300"
            }`}
          />
          <span className="ml-2 text-sm text-gray-600">
            {row.createdAt !== "N/A" ? "Active" : "Inactive"}
          </span>
        </div>
      ),
    },
    {
      key: "username",
      label: "Username",
    },
    {
      key: "fullName",
      label: "Name",
    },
    {
      key: "dateOfBirth",
      label: "Date of Birth",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "createdAt",
      label: "Joined On",
    },
  ];

  return (
    <div className="w-full h-full px-5">
      <h1 className="text-3xl mt-4 font-semibold text-left">Supervisors</h1>

      <div className="mt-5 rounded-lg">
        <CustomTable skeleton={true} data={supervisors} columns={columns} searchable={true} />
      </div>

    </div>
  );
};

export default Supervisor;
